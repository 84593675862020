<template>
    <div class="invitation-sign-up mt-5 px-2">
        <div class="max-w-500 mx-auto">
            <ui-card>
                <div class="text-center">
                    <img class="w-50 my-4" src="https://pay.ecart.com/img/logo-negro.svg" alt="" />
                </div>
                <div v-if="!!error" class="alert alert-danger d-flex align-items-center" role="alert">
                    <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:">
                        <use xlink:href="#exclamation-triangle-fill"/>
                    </svg>
                    <small>
                        {{ error }}
                    </small>
                </div>
                <ui-sign-up-skeleton v-if="loading && !error" />
                <template v-if="!loading && invitation">
                    <h5>Account registration</h5>
                    <p class="text-muted mb-0">
                        Hello <b>{{ invitation.email }}</b>, please enter the information requested below to finalize your registration.
                    </p>
                    <form @submit.prevent="registerAdmin">
                        <information-form ref="informationForm" />
                        <form-password ref="passwordForm" />
                        <ui-button
                            type="submit"
                            class="w-100"
                            variant="primary"
                            :disabled="!!error"
                            :loading="registering"
                        >
                            Continue
                        </ui-button>
                    </form>
                </template>
            </ui-card>
        </div>
    </div>
</template>

<script>
import { decrypt } from '@tendencys/encrypter';

import FormPassword from '@/components/forms/FormPassword.vue';
import InformationForm from '@/components/invitation/InformationForm.vue';
import UiButton from '@/components/ui/buttons/Button'
import UiCard from '@/components/ui/Card.vue';
import UiSignUpSkeleton from '@/components/ui/skeleton/SignUpSkeleton.vue';

export default {
    components: {
        FormPassword,
        InformationForm,
        UiButton,
        UiCard,
        UiSignUpSkeleton,
    },
    data() {
        return {
            error: null,
            invitation: null,
            loading: true,
            registering: false,
        };
    },
    mounted() {
        const { _v } = this.$route.query;

        if (!_v) {
            this.error = 'The request is required to have the "_v" parameter.';
        } else {
            const { invitation_id } = JSON.parse(decrypt(_v, process.env.VUE_APP_TENCRYPT));
            this.getInvitation(invitation_id);
        }
    },
    methods: {
        async getInvitation(id) {
            try {
                this.loading = true;

                const { data } = await this.$axios.get(`/_/invitations/${id}`, this.formData);

                this.invitation = data;
            } catch (error) {
                this.error = this.getErrorMessage(error);
            } finally {
                this.loading = false;
            }
        },
        async registerAdmin() {
            const informationForm = this.$refs['informationForm'];
            const passwordForm = this.$refs['passwordForm'];
            const validated_information = await informationForm.validate();
            const validated_password = await passwordForm.validate();

            if (!validated_information || !validated_password){
                return;
            }

            try {
                this.registering = true;

                await this.$axios.post('/_/admins', {
                    ...informationForm.formData,
                    ...passwordForm.formData,
                });

                this.$router.push('/login');
                this.$toast.success('Your account has been created. You may now login with the information you provided.');
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.registering = false;
            }
        },
    },
};
</script>

<style>
.invitation-sign-up {
    margin: 0 auto;
}
</style>
