<template>
    <div class="information-form">
        <div class="row">
            <div class="col-md-6">
                <ui-input
                    id="first_name"
                    name="first_name"
                    class="mb-4"
                    label="First name"
                    type="text"
                    v-model:value="formData.first_name"
                    :field="v$.formData.first_name"
                />
            </div>
            <div class="col-md-6">
                <ui-input
                    id="last_name"
                    name="last_name"
                    class="mb-4"
                    label="Last name"
                    type="text"
                    v-model:value="formData.last_name"
                    :field="v$.formData.last_name"
                />
            </div>
            <div class="col-md-12">
                <ui-input
                    id="phone"
                    name="phone"
                    class="mb-4"
                    label="Phone number"
                    type="text"
                    v-model:value="formData.phone"
                    autocomplete="new-password"
                    :field="v$.formData.phone"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';

import UiInput from '@/components/ui/Input';

export default {
    name: 'InformationForm',
    components: { UiInput },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            loading: false,
            error: null,
            formData: {
                first_name: '',
                last_name: '',
                phone: '',
            },
        };
    },
    validations() {
        return {
            formData: {
                first_name: {
                    required: helpers.withMessage('First name is required.', required),
                    $autoDirty: true,
                },
                last_name: {
                    required: helpers.withMessage('Last name is required.', required),
                    $autoDirty: true,
                },
                phone: {
                    required: helpers.withMessage('Phone is required.', required),
                    $autoDirty: true,
                }
            },
        };
    },
    methods: {
        async validate() {
           return await this.v$.$validate();
        }
    },
};
</script>

