<template>
    <div class="sign-up-skeleton">
        <ui-skeleton class="w-50 mb-3" />
        <ui-skeleton class="w-100 mb-1" />
        <ui-skeleton class="w-100 mb-4" />
        <div class="row">
            <div class="col-md-6 mb-4">
                <ui-skeleton class="w-100" type="input" />
            </div>
            <div class="col-md-6 mb-4">
                <ui-skeleton class="w-100" type="input" />
            </div>
            <div class="col-md-12 mb-4">
                <ui-skeleton class="w-100" type="input" />
            </div>
            <div class="col-md-12 mb-2">
                <ui-skeleton class="w-100" type="input" />
            </div>
            <div class="col-md-12 mb-4">
                <hr />
                <ui-skeleton class="w-75 mb-2" />
                <ui-skeleton class="w-75 mb-2" />
                <ui-skeleton class="w-75" />
            </div>
            <div class="col-md-12">
                <ui-skeleton class="w-100" type="input" />
            </div>
        </div>
    </div>
</template>

<script>
import UiSkeleton from '@/components/ui/skeleton/Skeleton.vue';

export default {
    name: 'SignUpSkeleton',
    components: { UiSkeleton },
    props: {
        rows: {
            type: Number,
            default: 10,
            required: false,
        }
    },
    data() {
        return {};
    },
};
</script>
